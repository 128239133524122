import moment from 'moment';
moment.locale('es');

export default {
  methods: {
    formatShowDate(date) {
      return moment(date).format('DD/MM/YYYY hh:mm A');
    },
  },
};
